
export default {
  name: 'CardsGroup',
  components: {
    DefaultComponent: () => import('./default-slice'),
    WithButton: () => import('./cardsGroupWithButtons'),
    CollapsibleWithButton: () => import('./collapsibleWithButtons'),
  },
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  computed: {
    variation() {
      return this.slice.variation
    },
    component() {
      const mappings = {
        'default-slice': 'DefaultComponent',
        cardsGroupWithButtons: 'WithButton',
        collapsibleWithButtons: 'CollapsibleWithButton',
      }

      return mappings[this.variation] || 'DefaultComponent'
    },
  },
}
